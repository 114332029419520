<template>
  <section>
    <confirm-workshop-section :WorkshopId="getWorkshopId" :Email="getEmail" />
  </section>
</template>

<script>
import ConfirmWorkshopSection from "@/components/ConfirmWorkshop/ConfirmWorkshopSection";
export default {
  components: {
    ConfirmWorkshopSection,
  },
  computed: {
    getWorkshopId() {
      return this.$route.params.workshopId;
    },
    getEmail() {
      return this.$route.params.email;
    },
  },
};
</script>

<style lang="scss" scoped></style>
