<template>
  <div id="confirm-workshop-section-content">
    <div v-if="error">
      <h1>Error</h1>
      <h3>{{ message }}</h3>
    </div>
    <div v-else>
      <h1>{{ message }}</h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "confirm-workshop-section",
  props: ["WorkshopId", "Email"],
  data() {
    return {
      error: false,
      message: null,
    };
  },
  methods: {
    async confirmWorkshop() {
      const res = await this.$http
        .get(
          "userworkshop/confirm?workshopId=" +
            this.WorkshopId +
            "&email=" +
            this.Email
        )
        .json();
      if (res.error) {
        this.error = true;
      }
      this.message = res.message;
    },
  },
  mounted() {
    this.confirmWorkshop();
  },
};
</script>
<style lang="scss" scoped>
#confirm-workshop-section-content {
  margin: 10rem 0 5rem 0;
}
</style>
